<template>
  <div>
    <el-image :src="require('@/assets/images/pc/league.png')" />
  </div>
</template>
    
<script>
import Tools from "@/tool";
import Config from "@/api/service";
import Manager from "@/manager";
export default {
  name: "PCModule",
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  methods: {
    //添加访问商品记录
    addVisitProductRecord() {
      Manager.addVisitProductRecord("加盟代理");
    },
  },
  mounted() {
      this.addVisitProductRecord();
  }
};
</script>
<style lang="less" scoped></style>