<template>
  <div>
    <van-sticky>
      <title-bar2 title="加盟代理" />
    </van-sticky>
    <el-image class="img" :src="require('@/assets/images/mt/league.png')">
      <template v-slot:loading>
        <van-loading type="spinner" size="20" />
      </template>
    </el-image>
  </div>
</template>
    
<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import Tools from "@/tool";
import Config from "@/api/service";
import Manager from "@/manager";
export default {
  name: "MTModule",
  components: {
    TitleBar2,
  },
  props: {
  },
  data() {
    return {
    };
  },
  methods: {
    //添加访问商品记录
    addVisitProductRecord() {
      Manager.addVisitProductRecord("加盟代理");
    },
  },
  mounted() {
    this.addVisitProductRecord();
  }
};
</script>
<style lang="less" scoped>
.img {
  width: 100%;
}
</style>